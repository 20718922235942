import React, { useState, useCallback } from 'react';
import CourseTypeOne from './CourseTypeOne';
import CourseData from '../../../data/course/CourseData.json';
import debounce from 'lodash/debounce';

const CourseGrid = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCourses, setFilteredCourses] = useState(CourseData);
    const [currentPage, setCurrentPage] = useState(1);
    const coursesPerPage = 6;

    const handleSearch = useCallback(
        debounce((query) => {
            const filtered = CourseData.filter(course => 
                course.title.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredCourses(filtered);
            setCurrentPage(1); // Reset to first page when searching
        }, 300),
        []
    );

    // Calculate pagination
    const indexOfLastCourse = currentPage * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);
    const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <section className="section-padding page">
            <div className="course-top-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <p>Showing {currentCourses.length} of {filteredCourses.length} results</p>
                        </div>
        
                        <div className="col-lg-4">
                            <div className="topbar-search">
                                <form method="get" action="#" onSubmit={(e) => e.preventDefault()}>
                                    <input 
                                        type="text"  
                                        placeholder="Search our courses" 
                                        className="form-control"
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                            handleSearch(e.target.value);
                                        }}
                                    />
                                    <label><i className="fa fa-search"></i></label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="container">
                <div className="row">
                    {currentCourses.map((item) => (
                        <div className="course-item col-lg-4 col-md-6 col-sm-6" key={item.id}>
                            <CourseTypeOne data={item} />
                        </div>
                    ))}
                </div>

                {/* Pagination */}
                <div className="row mt-5">
                    <div className="col-12">
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button 
                                        className="page-link" 
                                        onClick={() => paginate(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                    <li 
                                        key={index} 
                                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                    >
                                        <button 
                                            className="page-link" 
                                            onClick={() => paginate(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button 
                                        className="page-link" 
                                        onClick={() => paginate(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseGrid;
